<template>
    <div class="app-main__inner">
        <div class="container  mb-3">
            <div class="row" v-if="checking"></div>
            <div class="row p-5" v-else>
                
                <div class="col-md-12 text-center" v-if="successCheck">
                    <div class="flex justify-center">
                        <svg class="h-40 w-40 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="text-xl link-theme-color mt-4 text-gray-600">
                        {{ $t('switchCard') }} <br>
                        {{ $t('redirectWait') }}
                    </div>
                </div>
                <div class="col-md-12 text-center" v-else>
                    <div class="flex justify-center">
                        <svg class="h-40 w-40 text-red-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                    </div>
                    <div class="text-xl font-light link-theme-color mt-4 text-gray-600">
                        {{ $t('invalidSession') }}
                    </div>
                </div>
            </div>
        </div>
        <Notification />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex'

    export default {
        name : 'billing-dashboard',
        components : {
            Notification : () => import('./../../customComponent/NotificationAlert'),
        },
        data () {
            return {
                PaymentType : null,
                history : [],
                checking : true,
                successCheck : false,
                errorCheck : true,
                updating : false,
                paymentCardModal : false,
                processingCard : false
            }
        },
        computed : {
            ...mapGetters({
                USER_TOKEN : 'auth/USER_TOKEN',
                USER_REFRESH : 'auth/USER_REFRESH',
                GET_USER_COMPANY_ID : 'customer/GET_USER_COMPANY_ID',
                GET_COMPANY_BILLING : 'customer/GET_COMPANY_BILLING',
                GET_PAYMENT_TYPE : 'customer/GET_PAYMENT_TYPE',
            })
        },
        mounted () {
            this.checking = true
            this.checkSessionId()
        },
        methods : {
            checkSessionId () {
                const sessionId = window.localStorage.getItem('stripe_session')
                if (sessionId === this.$route.query.session_id) {
                    this.checking = false
                    this.successCheck = true
                    this.getUserSwitchPayment()
                } else {
                    this.checking = false
                    this.errorCheck = true
                    setTimeout(() => {
                        this.$router.push({ name : 'billings' })
                    }, 1000)
                }
            },
            getUserSwitchPayment () {
                const URL = this.GET_USER_COMPANY_ID ? this.$services.endpoints.GET_USER_SWITCH_PAYMENT + `${this.GET_USER_COMPANY_ID}/` : this.$services.endpoints.GET_USER_SWITCH_PAYMENT
                this.$store.dispatch('customer/getUserSwitchPayment', URL)
                .then(_ => {
                    this.successCheck = true
                    window.localStorage.removeItem('stripe_session')
                    setTimeout(() => {
                        this.$router.push({ name : 'billings' })
                    }, 1000)
                }).catch((err) => {
                    if (err.response.status === 400) {
                        if (err.response.data.code === 401) {
                            this.$store.dispatch('auth/refreshUserToken', this.USER_REFRESH)
                            .then(_ => {
                                this.getUserSwitchPayment()
                            })
                            .catch(_ => {
                                this.$store.commit('auth/CLEAR_AUTH_USER', null)
                                window.Bus.$emit('sign-out')
                            })
                        }
                    }
                })
            },
        }
    }
</script>

<style lang="scss" scoped>
.custom-btn-pay{
    background-color: #F167CA !important;
    border-color: #F167CA !important;
    font-weight: 800;
}
.custom-card-section{
    box-shadow: 0 0.46875rem 2.1875rem rgba(4,9,20,0.03),0 0.9375rem 1.40625rem rgba(4,9,20,0.03),0 0.25rem 0.53125rem rgba(4,9,20,0.05),0 0.125rem 0.1875rem rgba(4,9,20,0.03);
    border-width: 0;
    transition: all .2s;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26,54,126,0.125);
}
.custom-save-btn{
    background-color: #2CDCA7 !important;
    border-color: #2CDCA7 !important;
    font-weight: 800;
}
.override-form-field{
    border: 0;
    border-bottom: 2px solid rgb(64, 138, 235);
    box-shadow: none;
    background-color: #fff !important;
    border-radius: unset !important;
}
.col-form-label {
    padding-top: 1rem !important;
}
</style>